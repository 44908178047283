class MimeTypeHelper {
  private static mimeTypes: Record<string, Record<string, string[]>> = {
    text: {
      txt: ["text/plain"],
      html: ["text/html"],
      css: ["text/css"],
      js: ["text/javascript"],
      csv: ["text/csv"],
      xml: ["application/xml"],
    },
    images: {
      jpg: ["image/jpeg"],
      jpeg: ["image/jpeg"],
      png: ["image/png"],
      gif: ["image/gif"],
      bmp: ["image/bmp"],
      webp: ["image/webp"],
      svg: ["image/svg+xml"],
    },
    audio: {
      mp3: ["audio/mpeg"],
      wav: ["audio/wav"],
      ogg: ["audio/ogg"],
      aac: ["audio/aac"],
      midi: ["audio/midi"],
      mid: ["audio/midi"],
    },
    video: {
      mp4: ["video/mp4"],
      mov: ["video/quicktime"],
      avi: ["video/x-msvideo"],
      wmv: ["video/x-ms-wmv"],
      webm: ["video/webm"],
      mkv: ["video/x-matroska"],
    },
    application: {
      pdf: ["application/pdf"],
      json: ["application/json"],
      zip: ["application/zip", "application/zip-compressed"],
      gzip: ["application/gzip"],
      gz: ["application/gzip"],
      rar: ["application/vnd.rar"],
      "7z": ["application/x-7z-compressed"],
      doc: ["application/msword"],
      docx: ["application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
      xls: ["application/vnd.ms-excel"],
      xlsx: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
      ppt: ["application/vnd.ms-powerpoint"],
      pptx: ["application/vnd.openxmlformats-officedocument.presentationml.presentation"],
    },
    scripts: {
      sh: ["application/x-sh"],
      exe: ["application/vnd.microsoft.portable-executable"],
      jar: ["application/java-archive"],
      php: ["application/x-httpd-php"],
      py: ["text/x-python"],
    },
    fonts: {
      ttf: ["font/ttf"],
      otf: ["font/otf"],
      woff: ["font/woff"],
      woff2: ["font/woff2"],
    },
    compressed: {
      zip: ["application/zip", "application/zip-compressed"],
      tar: ["application/x-tar"],
      gzip: ["application/gzip"],
      gz: ["application/gzip"],
      rar: ["application/vnd.rar"],
      "7z": ["application/x-7z-compressed"],
    },
  };

  /**
   * Get MIME type for a given file extension
   * @param extension - The file extension (e.g., "jpg", "pdf")
   * @returns The corresponding MIME type, or `null` if not found
   */
  public static getMimeType(extension: string): string[] | null {
    const ext = extension.toLowerCase();

    for (const category in MimeTypeHelper.mimeTypes) {
      if (MimeTypeHelper.mimeTypes[category][ext]) {
        return MimeTypeHelper.mimeTypes[category][ext];
      }
    }

    return null;
  }

  /**
   * Get all supported file extensions
   * @returns A list of all supported file extensions
   */
  public static getAllExtensions(): string[] {
    return Object.values(MimeTypeHelper.mimeTypes)
      .flatMap((category) => Object.keys(category))
      .sort();
  }

  /**
   * Get all file extensions for a given MIME type
   * @returns A list of all file extensions for the given MIME type
   * @param category
   */
  public static getExtensionsByCategory(category: keyof typeof MimeTypeHelper.mimeTypes): string[] {
    if (!MimeTypeHelper.mimeTypes[category]) {
      throw new Error(`Category "${category}" not found`);
    }

    return Object.keys(MimeTypeHelper.mimeTypes[category]);
  }

  /**
   * Get all supported MIME types
   * @returns A list of all supported MIME types
   */
  public static getAllMimeTypes(): string[][] {
    return Object.values(MimeTypeHelper.mimeTypes)
      .flatMap((category) => Object.values(category))
      .sort();
  }

  /**
   * Get all supported categories
   * @returns A list of all supported categories
   */
  public static getAllCategories(): string[] {
    return Object.keys(MimeTypeHelper.mimeTypes);
  }
}

export default MimeTypeHelper;
