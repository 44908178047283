import "./critical.scss";
import "./style.scss";

import "flickity";
import CrownForm from "./CrownForm";
import Tracking from "./Tracking";

const consentEvt = (e: Event) => {
  const acceptedCategories = (<CustomEvent>e).detail || {};

  if (acceptedCategories?.performance) {
    const tracking = new Tracking();
    tracking.init();
  } else {
    Tracking.clear();
  }
};

window.addEventListener("cf_consent", consentEvt);
window.addEventListener("cf_consent_loaded", consentEvt);

const navToggles = document.querySelectorAll(".nav-toggle");
if (navToggles) {
  navToggles.forEach((navToggle) => {
    navToggle.addEventListener("click", (e) => {
      e.preventDefault();

      document.body.classList.toggle("no-overflow");
      document.getElementById("site-nav")?.classList.toggle("nav--opened");
      navToggles.forEach((el) => el.classList.toggle("hamburger--toggled"));

      e.stopPropagation();
    });
  });
}

const navHandles = document.querySelectorAll(".nav__handle");
if (navHandles) {
  navHandles.forEach((navHandle) => {
    navHandle.addEventListener("click", (e) => {
      const navItem = navHandle.closest(".nav__item");
      const dropdown = navHandle.nextElementSibling;

      if (!dropdown) return;

      e.preventDefault();

      navItem?.classList.toggle("nav__item--opened");
    });
  });
}

const heroChips = document.querySelectorAll(".hero .chips__item");
if (heroChips) {
  heroChips.forEach((chip, i) => {
    chip.addEventListener("click", function (e) {
      e.preventDefault();

      const heroImages = document.querySelectorAll(".hero__image");
      const chipsIndicator = this.closest(".chips");

      const heroContentBlocks = document.querySelectorAll(
        ".hero__content-block"
      );

      heroImages?.forEach((block) =>
        block.classList.remove("hero__image--active")
      );

      heroContentBlocks?.forEach((block) =>
        block.classList.remove("hero__content-block--active")
      );

      heroChips?.forEach((chip) =>
        chip.classList.remove("chips__item--active")
      );

      this.classList.add("chips__item--active");

      chipsIndicator.style.setProperty("--chips-indicator", `${i * 100}%`);
      const customBgColour = this.dataset.selectedBg;
      if (customBgColour) {
        chipsIndicator.style.setProperty(
          "--chips-indicator-bg",
          customBgColour
        );
      } else {
        chipsIndicator.style.setProperty(
          "--chips-indicator-bg",
          "var(--clr-secondary)"
        );
      }

      heroImages[i]?.classList.add("hero__image--active");
      heroContentBlocks[i]?.classList.add("hero__content-block--active");
    });
  });
}

document.addEventListener("keyup", (e) => {
  // close nav if open and clicking Escape
  const isNavOpen = document
    .getElementById("site-nav")
    ?.classList.contains("nav--opened");

  if (e.key === "Escape" && isNavOpen) {
    document.body.classList.remove("no-overflow");
    document.getElementById("site-nav")?.classList.remove("nav--opened");
  }
});

const accordionToggles = document.querySelectorAll(".accordion__toggle");
for (const accordionToggle of accordionToggles) {
  accordionToggle.addEventListener("click", (e) => {
    e.preventDefault();

    // get containing accordion
    const accordionEl = accordionToggle.previousElementSibling as HTMLDetailsElement;

    if (!accordionEl) return;

    // invert state
    accordionEl.open = !accordionEl.open;
  });
}

document.addEventListener("click", (e: Event) => {
  // close nav if open and clicking outside of nav
  const isNavOpen = document
    .getElementById("site-nav")
    ?.classList.contains("nav--opened");

  const clickedOutsideOfNav = !document
    .getElementById("site-nav")
    ?.contains(e.target as Node);

  if (clickedOutsideOfNav && isNavOpen) {
    document.body.classList.remove("no-overflow");
    document.getElementById("site-nav")?.classList.remove("nav--opened");
  }
});

const quoteForm = document.querySelector<HTMLFormElement>("#quote");
if (quoteForm) {
  new CrownForm({
    el: quoteForm,

    url: "/wp-content/themes/crownoilenvironmental/api/QuoteForm.php",

    button: {
      submittingText: "Submitting...",
      defaultText: "Get My Quote",
    },

    success() {
      window.location.href = "/thank-you/enquiry/";
    },
  });
}

const contactForm = document.querySelector<HTMLFormElement>("#contact");
if (contactForm) {
  new CrownForm({
    el: contactForm,

    url: "/wp-content/themes/crownoilenvironmental/api/ContactForm.php",

    success() {
      window.location.href = "/thank-you/contact/";
    },
  });
}

const freeFuelTestForm = document.querySelector<HTMLFormElement>("#free-fuel-test");
if (freeFuelTestForm) {
  new CrownForm({
    el: freeFuelTestForm,

    url: "/wp-content/themes/crownoilenvironmental/api/FreeFuelTestForm.php",

    success() {
      window.location.href = "/thank-you/free-fuel-test/";
    },
  });
}

const hero = document.querySelector(".hero");
if (hero) {
  new IntersectionObserver(
    ([hero]) => {
      const quoteFormContainer = document.querySelector(".quote-form-container");

      if (!quoteFormContainer) return;

      quoteFormContainer.classList[
        hero.intersectionRatio <= 0.75 ? "add" : "remove"
        ]("quote-form-container--sticky");
    },
    {
      threshold: [0, 0.25, 0.5, 0.75],
    }
  ).observe(hero);

  new IntersectionObserver(
    ([hero]) => {
      if (hero.isIntersecting) {
        document.body.classList.remove("header-scrolled");
      } else {
        document.body.classList.add("header-scrolled");
      }
    },
    {
      rootMargin: "-50% 0px 0px 0px",
    }
  ).observe(hero);
}

const footer = document.querySelector(".footer");
if (footer) {
  new IntersectionObserver(
    ([footer]) => {
      const quoteFormContainer = document.querySelector(".quote-form-container");

      quoteFormContainer?.classList[footer.isIntersecting ? "add" : "remove"](
        "quote-form-container--docked"
      );
    },
    {
      rootMargin: "0px 0px -100px 0px",
    }
  ).observe(footer);
}

document.addEventListener("touchstart", () => {
  return false;
});
