/**
 * This file is part of the Crown Group websites.
 * Author: Mark Eriksson <https://www.markwrites.codes/>
 */

import MimeTypeHelper from "./MimeTypeHelper";

const enum ButtonState {
  LOCK = "LOCK",
  UNLOCK = "UNLOCK",
}

export type Response = {
  error: { code: string; data: string | string[] };
  success: boolean;
  validation: {
    telephone: { result: boolean; data: Record<string, string> };
    email: { result: boolean; data: Record<string, string> };
  };
  form_data: Record<string, string>;
};

export type CrownFormFileConfig = {
  maxFileSize?: number;
  allowedExtensions?: string[];
  maxFiles?: number;
  messages?: {
    maxFileSize?: string;
    allowedExtensions?: string;
    maxFiles?: string;
  },
  handle?: (files: File[]) => void;
}

class CrownForm {
  el!: HTMLFormElement;
  url!: string;
  files!: Record<string, CrownFormFileConfig> | undefined;
  success!: Function;
  error!: Function;
  button!: {
    el?: HTMLButtonElement | null;
    defaultText: string;
    submittingText: string;
  };

  constructor({
                el,
                url,
                files,
                success,
                error,
                button,
              }: {
    el: HTMLFormElement;
    url?: string;
    files?: Record<string, CrownFormFileConfig>;
    success?: Function;
    error?: Function;
    button?: {
      el?: HTMLButtonElement | null;
      defaultText: string;
      submittingText: string;
    };
  }) {
    if (!el) return;

    this.el = el;

    this.url = url || el.action;

    this.files = files || undefined;

    this.success = success?.bind(this) || ((response: Response) => {
      this.addSuccess("Form submitted successfully.");
      console.log(response);
    });

    this.error = error?.bind(this) || ((response: Response) => {
      const { code = "N/A", data = "N/A" } = response.error;
      this.addError(`An unexpected error occurred. Please contact us with the following error code and message:<br /><br />Code: ${code}<br />Message: ${JSON.stringify(data)}`);
      console.error(response);
    });

    this.button = button || {
      defaultText: "Submit",
      submittingText: "Submitting...",
    };

    this.button.el = this.el.querySelector<HTMLButtonElement>(
      ".form__button--submit",
    )!;

    this.el.classList.add("crown-form");
    this.el.setAttribute("novalidate", "novalidate");
    this.el.addEventListener("submit", this.submit.bind(this));

    this.watchFileInputs();

    this.bindInputEvents();
  }

  /**
   * Submit the form to the server
   * @param e The submit event object of the form element
   */
  async submit(e: Event): Promise<void> {
    e.preventDefault();

    try {
      this.setButtonState(ButtonState.LOCK);

      if (this.checkValidity()) {
        const data = new FormData(this.el);

        data.append("cgAnalytics", sessionStorage.getItem("cgAnalytics") || "");

        const request = await fetch(this.url, {
          method: "POST",
          body: data,
        });

        const response = await request.json();

        if (response.success) {
          this.success(response);
        } else {
          const { code, data } = response.error;

          switch (code) {
            case "EMPTY_FIELDS":
              if (data.length > 0) {
                CrownForm.addError(
                  "Please enter/select a value.",
                  `#${CSS.escape(data[0])}`,
                );
              }
              break;

            case "INVALID_TELEPHONE":
              CrownForm.addError(
                "Please enter a valid telephone number.",
                `#${CSS.escape(data)}`,
              );
              break;

            case "INVALID_EMAIL":
              let message = "Please enter a valid email address.";
              const { did_you_mean } = response?.validation?.email?.data;

              if (did_you_mean) {
                message = `Invalid email. Did you mean: ${did_you_mean}?`;
              }

              CrownForm.addError(message, `#${CSS.escape(data)}`);
              break;

            case "HONEYPOT":
              this.el.remove();
              break;

            case "RATE_LIMIT_EXCEEDED":
              this.addError("You are submitting too many forms too quickly. Please try again later.");
              break;

            case "UNKNOWN_ERROR":
              this.addError(`An unexpected error occurred. Please contact us with the following error code and message:<br /><br />Code: ${code}<br />Message: ${JSON.stringify(data)}`);
              console.error(response.error);
              break;

            default:
              this.error(response);
              break;
          }

          this.setButtonState(ButtonState.UNLOCK);
        }
      } else {
        this.setButtonState(ButtonState.UNLOCK);
      }
    } catch (error) {
      this.setButtonState(ButtonState.UNLOCK);
      this.error(error);
    }
  }

  /**
   * Check validity of the form fields
   * @return boolean Whether the form contains all valid fields
   */
  checkValidity(): boolean {
    for (const element of this.el.elements) {
      const el = element as HTMLInputElement;

      if (!el.checkValidity()) {
        CrownForm.addError(el.validationMessage, el);

        return false;
      }
    }

    return true;
  }

  /**
   * Toggle the disabled state of the submit button
   * @param state The state to set the button to
   */
  setButtonState(state: ButtonState): void {
    if (!this.button.el) return;

    if (state === ButtonState.LOCK) {
      this.button.el.setAttribute("disabled", "disabled");
      this.button.el.innerText = this.button.submittingText || "Submitting...";
    } else if (state === ButtonState.UNLOCK) {
      this.button.el.removeAttribute("disabled");
      this.button.el.innerText = this.button.defaultText || "Submit";
    }
  }

  /**
   * Bind input events to the fields
   */
  bindInputEvents(): void {
    for (const element of this.el.elements as HTMLFormControlsCollection) {
      element.addEventListener("input", (): void => {
        CrownForm.clearAlerts.call(this.el, element);
      });
    }
  }

  /**
   * Watch file inputs for changes to validate them
   */
  watchFileInputs(): void {
    if (!this.files) return;

    for (const [name, config] of Object.entries(this.files)) {
      const input = this.el.querySelector<HTMLInputElement>(`#${name}`);

      if (!input) continue;

      const { maxFileSize, allowedExtensions, maxFiles = 1, handle } = config;

      if (maxFiles > 1) {
        input.setAttribute("multiple", "multiple");
      }

      if (allowedExtensions) {
        input.setAttribute("accept", allowedExtensions.map((ext) => `.${ext}`).join(","));
      }

      input.addEventListener("change", (e: Event): void => {
        const files = (<HTMLInputElement>e.target).files;

        if (!files) return;

        for (const file of files) {
          let errorMessage;

          if (maxFiles && files.length > maxFiles) {
            errorMessage = config.messages?.maxFiles || `You can only upload a maximum of ${maxFiles} files.`;
          } else if (maxFileSize && file.size > maxFileSize) {
            errorMessage = config.messages?.maxFileSize || `File size is too large. Maximum file size is ${maxFileSize / 1024 / 1024}MB.`;
          } else if (allowedExtensions) {
            const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
            const mimeType = MimeTypeHelper.getMimeType(fileExtension) || [];

            // file extension is not supported, or mime-types are mismatched
            if (!allowedExtensions.includes(fileExtension) || !mimeType.includes(file.type)) {
              errorMessage = config.messages?.allowedExtensions || `File type not supported. Supported file types are: ${allowedExtensions.join(", ")}`;
            }
          }

          if (errorMessage) {
            CrownForm.addError(errorMessage, input);
            input.value = "";
            return;
          }
        }

        // pass the files back to the user to process, such as showing previews
        if (handle) {
          handle.call(this, [...files]);
        }
      });
    }
  }

  /**
   * Add an error alert to the form
   * @param message The message to display
   */
  addError(message: string): void {
    CrownForm.addAlert(message, this, "error");
  }

  /**
   * Add a success alert to the form
   * @param message The message to display
   */
  addSuccess(message: string): void {
    CrownForm.addAlert(message, this, "success");
  }

  /**
   * Add an alert to the form
   * @param message The message to display
   */
  addAlert(message: string): void {
    CrownForm.addAlert(message, this);
  }

  /**
   * Add an error to the form/field
   * @param message The message to display
   * @param field The field or CrownForm instance to display the message on
   */
  static addError(message: string, field: Element | string | CrownForm): void {
    CrownForm.addAlert(message, field, "error");
  }

  /**
   * Add a success alert to the form/field
   * @param message The message to display
   * @param field The field or CrownForm instance to display the message on
   */
  static addSuccess(message: string, field: Element | string | CrownForm): void {
    CrownForm.addAlert(message, field, "success");
  }

  static addAlert(message: string, field: Element | string | CrownForm, type: string = ""): void {
    let fieldToAlert;

    // grab the field to alert, or form if it's a CrownForm instance
    if (typeof field === "string") {
      fieldToAlert = document.querySelector(field) as HTMLElement;
    } else if (field instanceof CrownForm) {
      fieldToAlert = field.el;
    } else {
      fieldToAlert = field as HTMLElement;
    }

    if (!fieldToAlert) return;

    CrownForm.clearAlerts(fieldToAlert);

    // create the actual alert
    const messageEl = document.createElement("div");
    messageEl.classList.add("form__alert", `form__alert--${type}`);
    messageEl.innerHTML = message;

    // check if we're setting the error on a form or an input field
    if (field instanceof CrownForm) {
      const button = field.button.el;
      const buttonWrapper = button?.closest(".form__field");

      // if the button exists, insert the alert after it
      if (buttonWrapper) {
        buttonWrapper.insertAdjacentElement("afterend", messageEl);
      } else { // otherwise insert the error at the end of the form
        fieldToAlert.appendChild(messageEl);
      }
    } else {
      messageEl.classList.add("form__alert--input");

      const formField = fieldToAlert.closest(".form__field");
      formField?.querySelector(".form__input-wrapper")?.append(messageEl);

      fieldToAlert.focus();
    }
  }

  /**
   * Clear all alerts within a form
   */
  static clearAlerts(field: Element): void {
    field.closest(".crown-form")?.querySelectorAll(".form__alert").forEach((alert) => alert.remove());
  }
}

export default CrownForm;
