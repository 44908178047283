class Tracking {
  #data: { [key: string]: any } = {};

  constructor() {
    this.#data = Tracking.loadData();
  }

  get(key: string) {
    return this.#data[key];
  }

  set(key: string, value: any) {
    this.#data[key] = value;

    this.save();
  }

  static hasConsented() {
    if (!("CookieFirst" in window)) return false;

    // @ts-ignore
    return CookieFirst?.consent?.performance;
  }

  static loadData() {
    const data = sessionStorage.getItem("cgAnalytics");
    if (!data) {
      sessionStorage.setItem("cgAnalytics", JSON.stringify({}));
    }

    return JSON.parse(sessionStorage.getItem("cgAnalytics") || "");
  }

  static clear() {
    sessionStorage.removeItem("cgAnalytics");
  }

  save() {
    sessionStorage.setItem("cgAnalytics", JSON.stringify(this.#data));
  }

  init() {
    this.#data = Tracking.loadData();

    this.misc();
    this.checkReferrer();
    this.getUrlParams();
    this.updatePages();
    this.logUserJourney();
    this.logConsentPreferences();
  }

  misc() {
    const misc = this.get("misc") || {};

    if (misc?.sessionStarted) return;

    misc.sessionStarted = new Date().getTime();

    this.set("misc", misc);
  }

  logConsentPreferences() {
    if (!("CookieFirst" in window)) return;

    // @ts-ignore
    const consent = CookieFirst?.consent;

    this.set("consent", consent);
  }

  checkReferrer() {
    const misc = this.get("misc") || {};

    // don't want to overwrite the referrer from the first visit
    if (misc?.referrer) return;

    misc.referrer = document.referrer;

    this.set("misc", misc);
  }

  logUserJourney() {
    const journey = this.get("userJourney") || [];

    const lastJourney = journey[journey.length - 1];

    // check current page isn't same as last page viewed
    // prevents repeated logging of page refreshes etc
    if (lastJourney?.url === window.location.href) return;

    const timestamp = new Date().getTime();

    if (lastJourney) {
      lastJourney.timeOnPage = timestamp - lastJourney.timeVisited;
    }

    journey.push({
      url: window.location.href,
      timeVisited: timestamp,
      timeOnPage: 0,
    });

    this.set("userJourney", journey);
  }

  updatePages() {
    const pages = this.get("pages") || {};

    pages.lastPage = pages.currentPage || "";
    pages.currentPage = window.location.href;

    this.set("pages", pages);
  }

  getUrlParams() {
    const params = this.get("params") || {};
    if (params.length) return;

    const urlParams = new URLSearchParams(window.location.search);
    for (const [key, value] of urlParams) {
      params[key] = value;
    }

    this.set("params", params);
  }
}

export default Tracking;
